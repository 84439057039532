import {
  useAccount,
  useChainId,
  useReadContract,
  useReadContracts
} from 'wagmi'
import { useAllTorexes } from './useAllTorexes'
import { useMemo } from 'react'
import { Staking } from '@/types'
import {
  cfAv1ForwarderABI,
  cfAv1ForwarderAddress,
  emissionTreasuryABI,
  emissionTreasuryAddress,
  iSuperfluidPoolABI,
  superBoringABI,
  superBoringAddress
} from 'evm-contracts'
import { ChainId } from '@/config/networks'
import { Address, zeroAddress } from 'viem'
import { EMISSION_TREASURY_INITIAL_BALANCE } from '@/constants'

export const useStaking = () => {
  const { address: user } = useAccount()
  const userChainId = useChainId() as ChainId
  const { torexes } = useAllTorexes()

  const listOfTorexes = useMemo(
    () => Object.entries(torexes).map(([_, torex]) => torex),
    [torexes]
  )

  const { data: sleepPod } = useReadContract({
    query: {
      enabled: Boolean(user)
    },
    address: superBoringAddress[userChainId],
    abi: superBoringABI,
    functionName: 'getSleepPod',
    args: [user ?? zeroAddress]
  })

  const { data: distributionTaxRate } = useReadContract({
    query: {
      initialData: 0n
    },
    address: superBoringAddress[userChainId],
    abi: superBoringABI,
    functionName: 'DISTRIBUTION_TAX_RATE_PM'
  })

  const { data: rewardTokenTreasuryBalance } = useReadContract({
    address: emissionTreasuryAddress[userChainId],
    abi: emissionTreasuryABI,
    functionName: 'balanceLeft'
  })

  const contractReads = useMemo(
    () =>
      listOfTorexes.flatMap(
        ({ address, inboundToken, feeDistributionPool, rewardTokenPool }) => [
          {
            address: superBoringAddress[userChainId],
            abi: superBoringABI,
            functionName: 'getStakedAmountOf',
            args: [address, user ?? zeroAddress]
          },
          {
            address: superBoringAddress[userChainId],
            abi: superBoringABI,
            functionName: 'getTotalStakedAmount',
            args: [address]
          },
          {
            address: cfAv1ForwarderAddress[userChainId],
            abi: cfAv1ForwarderABI,
            functionName: 'getAccountFlowrate',
            args: [inboundToken.address, address]
          },
          {
            address: feeDistributionPool,
            abi: iSuperfluidPoolABI,
            functionName: 'getTotalFlowRate'
          },
          {
            address: feeDistributionPool,
            abi: iSuperfluidPoolABI,
            functionName: 'getMemberFlowRate',
            args: [user ?? zeroAddress]
          },
          {
            address: feeDistributionPool,
            abi: iSuperfluidPoolABI,
            functionName: 'getTotalAmountReceivedByMember',
            args: [user ?? zeroAddress]
          },
          {
            address: rewardTokenPool,
            abi: iSuperfluidPoolABI,
            functionName: 'getTotalFlowRate'
          },
          {
            address: rewardTokenPool,
            abi: iSuperfluidPoolABI,
            functionName: 'getMemberFlowRate',
            args: [sleepPod ?? zeroAddress]
          },
          {
            address: emissionTreasuryAddress[userChainId],
            abi: emissionTreasuryABI,
            functionName: 'getEmissionRate',
            args: [address]
          }
        ]
      ),
    [listOfTorexes, userChainId, user, sleepPod]
  )

  const {
    data,
    refetch: refetchStaking,
    isLoading
  } = useReadContracts({
    contracts: contractReads
  })

  const torexesWithStaking = useMemo(() => {
    if (data) {
      const readsLength = contractReads.length / listOfTorexes.length
      return listOfTorexes.reduce<Record<Address, Staking>>(
        (acc, torex, index) => {
          const userStaked = (data[index * readsLength]?.result as bigint) ?? 0n
          const totalStaked =
            (data[index * readsLength + 1]?.result as bigint) ?? 0n
          const volume = (data[index * readsLength + 2]?.result as bigint) ?? 0n
          const totalFeeDistributionFlowrate =
            (data[index * readsLength + 3]?.result as bigint) ?? 0n
          const userFeeDistributionFlowRate =
            (data[index * readsLength + 4]?.result as bigint) ?? 0n
          const totalFeeReceivedByUser =
            (data[index * readsLength + 5]?.result as bigint) ?? 0n
          const totalRewardFlowrate =
            (data[index * readsLength + 6]?.result as bigint) ?? 0n
          const userRewardFlowrate =
            (data[index * readsLength + 7]?.result as bigint) ?? 0n
          const rewardEmissionRate =
            (data[index * readsLength + 8]?.result as bigint) ?? 0n

          acc[torex.address] = {
            torex,
            userStaked,
            totalStaked,
            volume,
            totalFeeDistributionFlowrate,
            userFeeDistributionFlowRate,
            totalFeeReceivedByUser,
            totalRewardFlowrate,
            userRewardFlowrate,
            rewardEmissionRate
          }

          return acc
        },
        {}
      )
    }

    return {}
  }, [data, listOfTorexes, contractReads])

  const activeStakings = useMemo(
    () =>
      Object.values(torexesWithStaking).filter(
        (torexWithStaking) => torexWithStaking.userStaked > 0n
      ),
    [torexesWithStaking]
  )

  const totalStakedByUser = useMemo(
    () =>
      Object.values(torexesWithStaking).reduce(
        (acc, { userStaked }) => acc + userStaked,
        0n
      ),
    [torexesWithStaking]
  )

  const totalRewardsEmitted = useMemo(
    () =>
      EMISSION_TREASURY_INITIAL_BALANCE -
      (rewardTokenTreasuryBalance ?? EMISSION_TREASURY_INITIAL_BALANCE),
    [rewardTokenTreasuryBalance]
  )

  const totalRewardEmissionFlowrate = useMemo(
    () =>
      Object.values(torexesWithStaking).reduce(
        (acc, { rewardEmissionRate }) => acc + rewardEmissionRate,
        0n
      ),
    [torexesWithStaking]
  )

  const cumulativeUserRewardFlowrate = useMemo(
    () =>
      Object.values(torexesWithStaking).reduce(
        (acc, { userRewardFlowrate }) => acc + userRewardFlowrate,
        0n
      ),
    [torexesWithStaking]
  )

  const totalStakedOverall = useMemo(
    () =>
      Object.values(torexesWithStaking).reduce(
        (acc, { totalStaked }) => acc + totalStaked,
        0n
      ),
    [torexesWithStaking]
  )

  return {
    activeStakings,
    distributionTaxRate,
    cumulativeUserRewardFlowrate,
    refetchStaking,
    torexesWithStaking,
    totalStakedByUser,
    totalStakedOverall,
    totalRewardsEmitted,
    totalRewardEmissionFlowrate,
    isLoading
  }
}

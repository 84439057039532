import { FC, ReactNode, useMemo, useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'

import { Command, CommandGroup, CommandItem } from './ui/command'
import { CommandEmpty, CommandInput } from 'cmdk'
import { SuperTokenInfo, TokenInfo } from '@superfluid-finance/tokenlist'
import { Chain, formatUnits } from 'viem'

import { useTranslation } from '@/app/i18n/client'
import { TokenIcon } from './TokenIcon'
import { useBalances } from '@/hooks/useBalances'
import {
  calculateTotalBalance,
  cn,
  formatFiatValue,
  toFixedFromString
} from '@/utils'
import { HSeparator } from './Separator'
import { useTokenRelations } from '@/hooks/useTokenRelations'
import { usePrices } from './providers/PricesProvider'

const TokenRow: FC<{
  className?: string
  token: TokenInfo
  displayBalance: boolean
  formattedBalance: string
  price: number
}> = ({ className, token, displayBalance, formattedBalance, price }) => {
  return (
    <div
      className={cn(
        'w-full flex gap-4 items-center justify-between cursor-pointer p-2',
        className
      )}
    >
      <div className='flex flex-row gap-3 items-center'>
        <TokenIcon token={token} className='ml-2 scale-[1.5]' />
        <span className='text-base font-medium'>{token.name}</span>
      </div>

      {displayBalance && (
        <div className='flex flex-col gap-1 text-right'>
          <span className='text-base text-white'>
            {toFixedFromString(formattedBalance)}
          </span>
          <span className='text-sm text-gray-400'>
            {formatFiatValue(Number(formattedBalance), price)}
          </span>
        </div>
      )}
    </div>
  )
}

type TokenDialogProps = {
  selectedToken?: TokenInfo | SuperTokenInfo
  tokens: SuperTokenInfo[]
  selectedNetwork: Chain
  children: ReactNode
  displayTokenMode?: 'underlying' | 'super'
  displayBalanceMode?: 'mixed' | 'underlying' | 'super'
  onTokenSelect?: (token: TokenInfo | SuperTokenInfo) => void
}

export const TokenSelectDialog: FC<TokenDialogProps> = ({
  selectedToken,
  tokens: _tokens,
  selectedNetwork,
  children,
  displayBalanceMode,
  displayTokenMode,
  onTokenSelect
}) => {
  const { t } = useTranslation('token-dialog')
  const [open, setOpen] = useState(false)

  const { balances } = useBalances()
  const { getUnderlyingTokenOf, getSuperTokenOf } = useTokenRelations()
  const { getPrice } = usePrices()

  const tokens: {
    token: SuperTokenInfo
    displayToken: TokenInfo
    formattedBalance: string
    price: number
  }[] = useMemo(() => {
    return _tokens
      .map((superToken) => {
        const displayToken =
          (displayTokenMode === 'underlying'
            ? getUnderlyingTokenOf(superToken.address)
            : displayTokenMode === 'super'
            ? getSuperTokenOf(superToken.address)
            : superToken) ?? superToken

        if (!displayBalanceMode) {
          return {
            displayToken,
            token: superToken,
            formattedBalance: '0',
            price: 0
          }
        }

        const balance = balances.find(
          (b) => b.token.address === superToken.address
        )

        if (!balance || !displayBalanceMode) {
          return {
            displayToken,
            token: superToken,
            formattedBalance: '0',
            price: 0
          }
        }
        const price = getPrice(balance.token.address)

        if (displayBalanceMode === 'mixed') {
          const totalBalance = calculateTotalBalance(balance, 'super')
          const formattedBalance = formatUnits(
            totalBalance,
            balance.token.decimals
          )

          return {
            displayToken,
            token: superToken,
            formattedBalance,
            price
          }
        } else if (displayBalanceMode === 'super') {
          return {
            displayToken,
            token: superToken,
            formattedBalance: formatUnits(
              balance.token.balance,
              balance.token.decimals
            ),
            price
          }
        } /* if (displayBalanceMode === 'underlying') */ else {
          return {
            displayToken,
            token: superToken,
            formattedBalance: formatUnits(
              balance.underlying?.balance ?? 0n,
              balance.underlying?.decimals ?? 0
            ),
            price
          }
        }
      })
      .slice()
      .sort((a, b) => {
        const aTotalBalance = a.price * Number(a.formattedBalance)
        const bTotalBalance = b.price * Number(b.formattedBalance)

        if (aTotalBalance > bTotalBalance) return -1
        if (aTotalBalance < bTotalBalance) return 1

        return 0
      })
  }, [
    _tokens,
    balances,
    displayBalanceMode,
    displayTokenMode,
    getPrice,
    getUnderlyingTokenOf,
    getSuperTokenOf
  ])

  return (
    <Dialog open={onTokenSelect ? open : false} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className='w-full flex justify-center bg-transparent border-none'>
        <div className='bg-black p-4 w-full xxs:max-md:max-w-[320px] md:max-8xl:max-w-[420px] border border-gray-600 rounded-lg'>
          <DialogHeader className='py-4'>
            <DialogTitle>{t('title')}</DialogTitle>
            <DialogDescription>{t('description')}</DialogDescription>
          </DialogHeader>
          <Command>
            <CommandInput
              placeholder='Search token'
              className='bg-black mb-4 p-4 outline-none border rounded-md'
            />
            <CommandEmpty>{t('noTokens')}</CommandEmpty>
            <CommandGroup className='max-h-80 overflow-auto'>
              {tokens
                .filter((t) => t.token.chainId === selectedNetwork?.id)
                .map((t, i, arr) => (
                  <CommandItem
                    key={`${t.token.chainId}::${t.token.address}-${i}`}
                    className='flex-col items-start p-0'
                    value={t.token.symbol}
                    onSelect={() => {
                      onTokenSelect?.(t.token)
                      setOpen(false)
                    }}
                  >
                    <TokenRow
                      className='cursor-pointer hover:bg-gray-700 rounded-md my-3 py-3'
                      formattedBalance={t.formattedBalance}
                      token={t.displayToken}
                      price={t.price}
                      displayBalance={!!displayBalanceMode}
                    />
                    {i !== arr.length - 1 && <HSeparator className='my-0 ' />}
                  </CommandItem>
                ))}
            </CommandGroup>
          </Command>
        </div>
      </DialogContent>
    </Dialog>
  )
}

import { useFlowingAmounts } from '@/hooks/useFlowingAmounts'
import { FC } from 'react'

export const FlowingBalance: FC<{
  balance: bigint
  flowRate: bigint
  unitPrice?: number
  cutAtSignificantFlowingDecimal?: boolean
}> = ({
  balance,
  flowRate,
  unitPrice = 0,
  cutAtSignificantFlowingDecimal = false
}) => {
  const { flowingBalanceFormatted, flowingValue, significantFlowingDecimal } =
    useFlowingAmounts({
      startingBalance: balance,
      unitPrice,
      flowRate
    })

  const val = unitPrice > 0 ? flowingValue : flowingBalanceFormatted
  const dotIndex = val.indexOf('.')

  return cutAtSignificantFlowingDecimal
    ? val.substring(0, dotIndex + significantFlowingDecimal)
    : val
}

import { torexABI } from 'evm-contracts'
import { useEffect, useMemo, useState } from 'react'
import { useReadContracts } from 'wagmi'
import { useAllTorexes } from './useAllTorexes'
import { Address, ContractFunctionReturnType } from 'viem'

type TorexBenchmarkQuoteResult = ContractFunctionReturnType<
  typeof torexABI,
  'view',
  'getBenchmarkQuote'
>
export type BenchmarkQuoteByTorex = Record<Address, TorexBenchmarkQuoteResult>

type UseBenchmarkQuoteArgs = {
  torex?: Address
}

export function useBenchmarkQuote(): BenchmarkQuoteByTorex
export function useBenchmarkQuote(
  args: UseBenchmarkQuoteArgs
): TorexBenchmarkQuoteResult
export function useBenchmarkQuote(args?: UseBenchmarkQuoteArgs): unknown {
  const [benchmarkQuotes, setBenchmarkQuotes] = useState<BenchmarkQuoteByTorex>(
    {}
  )
  const { torexes } = useAllTorexes()

  const torexAddressList = useMemo(
    () => Object.keys(torexes).map((address) => address as Address),
    [torexes]
  )

  const { data: benchmarkQuoteData } = useReadContracts({
    contracts: torexAddressList.map((address) => ({
      address,
      functionName: 'getBenchmarkQuote',
      abi: torexABI,
      args: [BigInt(1e18)]
    }))
  })

  useEffect(() => {
    const benchmarkQuoteByTorex =
      torexAddressList.reduce<BenchmarkQuoteByTorex>((acc, address, index) => {
        if (benchmarkQuoteData) {
          const benchmarkQuote = (benchmarkQuoteData[index].result ?? [
            0n,
            0n,
            0n
          ]) as TorexBenchmarkQuoteResult

          acc[address] = benchmarkQuote
        }

        return acc
      }, {})

    setBenchmarkQuotes(benchmarkQuoteByTorex)
  }, [benchmarkQuoteData, torexAddressList])

  return args
    ? args.torex && benchmarkQuotes[args.torex]
      ? benchmarkQuotes[args.torex]
      : [0n, 0n, 0n]
    : benchmarkQuotes
}

import { escapeRegExp } from '@/utils'
import React, { forwardRef } from 'react'
import { Input as ShadCnInput } from '@/components/ui/input'

const inputRegex = RegExp('^\\d*(?:\\\\[.])?\\d*$') // match escaped "." characters via in a non-capturing group

interface InputProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'onChange' | 'as'> {
  value: string | number
  onUserInput: (input: string) => void
  error?: boolean
  fontSize?: string
  align?: 'right' | 'left'
  prependSymbol?: string
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      value,
      onUserInput,
      placeholder,
      prependSymbol,
      className,
      ...rest
    }: InputProps,
    ref
  ) => {
    const enforcer = (nextUserInput: string) => {
      if (
        nextUserInput === '' ||
        inputRegex.test(escapeRegExp(nextUserInput))
      ) {
        onUserInput(nextUserInput)
      }
    }

    return (
      <ShadCnInput
        className={className}
        {...rest}
        ref={ref}
        value={value}
        onChange={(event) => {
          if (prependSymbol) {
            const value = event.target.value

            // cut off prepended symbol
            const formattedValue = value.toString().includes(prependSymbol)
              ? value.toString().slice(1, value.toString().length + 1)
              : value

            // replace commas with periods, because uniswap exclusively uses period as the decimal separator
            enforcer(formattedValue.replace(/,/g, '.'))
          } else {
            enforcer(event.target.value.replace(/,/g, '.'))
          }
        }}
        // universal input options
        inputMode='decimal'
        autoComplete='off'
        autoCorrect='off'
        // text-specific options
        type='text'
        pattern='^[0-9]*[.,]?[0-9]*$'
        placeholder={placeholder || '0'}
        minLength={1}
        maxLength={79}
        spellCheck='false'
      />
    )
  }
)

Input.displayName = 'NumericalInput'

const MemoizedInput = React.memo(Input)
export { MemoizedInput as NumericalInput }

import { Button } from './ui/button'
import { CardSkeleton } from './CardSkeleton'
import { FC, ReactNode } from 'react'
import { FlowRate } from '@/types'
import { SuperTokenInfo } from '@superfluid-finance/tokenlist'
import { useConfetti } from '@/hooks/useConfetti'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from './ui/accordion'

type SuccessProps = {
  text: {
    title: string
    description: string
    ctaText: string
    accordionTrigger: string
  }
  videoOverlay?: ReactNode
  accordionContent?: ReactNode
  token?: SuperTokenInfo
  flowRate?: FlowRate
  onBack: () => void
}

export const Success: FC<SuccessProps> = ({
  text,
  accordionContent,
  videoOverlay,
  onBack
}) => {
  useConfetti('realistic')

  const { title, description, ctaText, accordionTrigger } = text

  return (
    <CardSkeleton
      title={title}
      description={description}
      cta={
        <Button
          data-testid='swap-button'
          disabled={false}
          loading={false}
          variant='brand'
          size='lg'
          className='rounded-2xl w-full'
          onClick={onBack}
        >
          {ctaText}
        </Button>
      }
      className='flex flex-col gap-0 animate-fade-left'
    >
      <div className='overflow-hidden rounded-xl relative py-16 px-20 xxs:max-sm:py-8 xxs:max-sm:px-10'>
        <video
          autoPlay
          loop
          muted
          playsInline
          preload='auto'
          style={{ filter: 'grayscale(100%)' }}
          className='rounded-xl scale-150 absolute top-0 left-0 w-full h-full object-cover'
        >
          <source src={require('../../public/success.mp4')} type='video/mp4' />
        </video>
        {videoOverlay}
      </div>
      {accordionContent && (
        <Accordion data-testid='success-accordion' type='single' collapsible>
          <AccordionItem
            className='border-none bg-black rounded-xl px-4'
            value='item-1'
          >
            <AccordionTrigger className='w-full flex justify-between items-center '>
              <p className='text-gray-400 text-sm'>{accordionTrigger}</p>
            </AccordionTrigger>
            <AccordionContent className='flex flex-col gap-1'>
              {accordionContent}
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      )}
    </CardSkeleton>
  )
}

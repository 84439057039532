import { FC, ReactNode, useState } from 'react'
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover'

type InfoPopoverProps = {
  content: ReactNode
  trigger: ReactNode
}

export const CustomPopover: FC<InfoPopoverProps> = ({ content, trigger }) => {
  const [open, setOpen] = useState(false)

  const handleMouseEnter = () => {
    setOpen(true)
  }

  const handleMouseLeave = () => {
    setOpen(false)
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleMouseEnter}
        onTouchEnd={handleMouseLeave}
        className='ring-0 focus:ring-0 focus:outline-none'
      >
        {trigger}
      </PopoverTrigger>
      <PopoverContent className='animate-fade'>{content}</PopoverContent>
    </Popover>
  )
}

import { useMemo } from 'react'
import { formatEther } from 'viem'

import useFlowingBalance from '@/hooks/useFlowingBalance'
import {
  formatFiatValue,
  getSignificantFlowingDecimal,
  toFixedFromString
} from '@/utils'

// FIX: Little too fast for the dashboard for now
type UseFlowingAmountsProps = {
  startingBalance: bigint
  unitPrice?: number
  flowRate: bigint
}

export const useFlowingAmounts = ({
  startingBalance,
  unitPrice,
  flowRate
}: UseFlowingAmountsProps) => {
  const flowingBalance = useFlowingBalance(startingBalance, flowRate)

  const significantFlowingDecimal = useMemo(
    () => getSignificantFlowingDecimal(flowRate),
    [flowRate]
  )

  const flowingValue = useMemo(
    () => formatFiatValue(Number(formatEther(flowingBalance)), unitPrice),
    [flowingBalance, unitPrice]
  )

  const { flowingBalanceFormatted, integerDecimalPlaces } = useMemo(() => {
    const flowingBalanceFormatted = toFixedFromString(
      formatEther(flowingBalance),
      significantFlowingDecimal
    )

    const integerDecimalPlaces = flowingBalanceFormatted.split('.')[0].length

    return {
      flowingBalanceFormatted,
      integerDecimalPlaces
    }
  }, [flowingBalance, significantFlowingDecimal])

  return {
    flowingBalanceFormatted,
    flowingBalance,
    significantFlowingDecimal,
    flowingValue,
    integerDecimalPlaces
  }
}

import { SuperTokenInfo, TokenInfo } from '@superfluid-finance/tokenlist'
import { ComponentPropsWithoutRef, ElementRef, FC, forwardRef } from 'react'
import { cn } from '@/utils/'
import { Button } from './ui/button'
import { TokenIcon } from './TokenIcon'
import { useTokenRelations } from '@/hooks/useTokenRelations'

type TokenChipProps = {
  dataTestid?: string
  token?: TokenInfo | SuperTokenInfo
  className?: string
  disabled?: boolean
  onClick?: (token: SuperTokenInfo) => void
}

export const TokenChip = forwardRef<
  ElementRef<FC<TokenChipProps>>,
  ComponentPropsWithoutRef<FC<TokenChipProps>>
>(({ dataTestid, token, disabled, onClick, className }, ref) => {
  return (
    <Button
      data-testid={dataTestid}
      ref={ref}
      type='button'
      variant={token ? 'secondary' : 'brand'}
      className={cn(
        'h-6 inline-flex basis-10 justify-center gap-1 items-center px-1 cursor-pointer rounded-lg'
      )}
      disabled={disabled}
      onClick={() => onClick?.((token ?? {}) as SuperTokenInfo)}
    >
      {token ? <TokenIcon token={token} className={className} /> : null}
      <p className={cn(token ? 'pr-2' : 'p-1 text-sm')}>
        {token?.symbol ?? 'Select'}
      </p>
    </Button>
  )
})
